import React from 'react';
import './_index.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Slide } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addGiftRequestItem, getGiftById } from '../../../../../redux/slices/parrain';
import line from '../../../../../assets/img/line.svg';
import { enqueueSnackbar } from '../../../../../redux/slices/notifier';
import formatAmount from '../../../../../utilities/formatAmount';

const ProductItem = ({ item }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const { addGiftRequestItemStatus } = useSelector((state) => state.parrain);
  const { informations } = useSelector((state) => state.user);

  const getGiftByIdHandler = () => {
    dispatch(getGiftById(item?.id)).then((res) => {
      history.push({
        pathname: `/parrain/product/${item?.id}`,
        state: res?.payload,
      });
    });
  };
  return (
    <div
      className={
        language === 'fr'
          ? 'add-to-cart-product-card fr-product-card product-card'
          : 'add-to-cart-product-card product-card'
      }
    >
      <div className="add-to-cart-product-img" onClick={getGiftByIdHandler}>
        <img
          src={
            item?.gift_images?.find((el) => el?.is_cover)?.file_url ||
            item?.gift_images?.[0]?.file_url
          }
          alt="product-img"
        />
      </div>
      <div className="add-to-cart-product-name">{item?.name}</div>
      <div className="add-to-cart-product-description">{item?.description}</div>
      <img src={line} alt="line-img" />
      <div className="add-to-cart-product-card-footer">
        <div
          className={
            language === 'ar'
              ? 'ar-add-to-cart-card-coins add-to-cart-card-coins'
              : 'add-to-cart-card-coins'
          }
        >
          {formatAmount(item?.amount, t)}
        </div>

        <div
          className={
            addGiftRequestItemStatus === 'loading'
              ? 'add-to-cart-card-btn disabled-btn'
              : 'add-to-cart-card-btn'
          }
          onClick={() =>
            informations?.gift_code
              ? dispatch(addGiftRequestItem(item?.id))
              : dispatch(
                  enqueueSnackbar({
                    message: t('You need to generate your gift code first'),
                    options: {
                      key: new Date().getTime() + Math.random(),
                      variant: 'warning',
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                      },
                      TransitionComponent: Slide,
                    },
                  })
                )
          }
          disabled={addGiftRequestItemStatus === 'loading'}
        >
          {t('Add to cart')}
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
