import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';
import { ord } from '../../utilities/methods';
import { closeSnackbar, enqueueSnackbar } from './notifier';
import { formatMessages } from '../../utilities/translateHelper';
import i18n from '../../services/i18n';
import { closeModal } from './modals';
import { TakiPopups } from 'taki-popups-plugin-v2';

export const me = () => async (dispatch) => {
  try {
    return await api.get('/auth/me').then((res) => {
      dispatch(meSuccess(res.data.payload));
      if (res && res.data?.payload) {
        let offers = null;
        if (res.data?.payload?.user?.affiliations.length > 0) {
          offers = res.data?.payload?.user?.affiliations
            .map((offer) => offer.group.name)
            .join(', ');
        }
        const idUser = res.data?.payload.user?.id;
        const email = res.data?.payload.user?.email;

        //softy-popups
        const nameOfUser = `${res.data?.payload?.user?.name} ${res.data?.payload.user?.last_name}`;
        let userData = res.data?.payload.user;

        let group = [];
        if (userData && userData.affiliations) {
          userData.affiliations.forEach((el) => {
            if (el && el.group && el.group.name) {
              group.push(el.group.name);
            }
          });
        }

        const studentLevels = res.data?.payload?.studentLevel.map((el) => {
          return `${el.subject.name}-${el.student_level.name}`;
        });
        const IdOfCurrentUser = idUser;

        // messagera
        TakiPopups({
          name: nameOfUser,
          memberId: IdOfCurrentUser,
          appId: "672b9ce26e32455831f9192d",
          meta_data: {
            division: userData?.division?.name,
            state: userData?.state?.name,
            affiliations: group,
            student_levels: studentLevels,
            domaineName: window.location.hostname,
            name: nameOfUser,
            email: email ? email.toString() : 'no-email@gmail.com',
            phone: res.data?.payload.user?.phone,
          }
        });
        window.$takiChat.push({
          id: idUser.toString(),
          name: res.data?.payload?.user?.last_name + ' ' + res.data?.payload?.user?.name,
          email: email ? email.toString() : 'no-email@gmail.com',
          phone: res.data?.payload.user?.phone,
          optional: [
            {
              key: 'classe',
              value: res.data?.payload.user?.division
                ? res.data?.payload.user.division?.name
                : 'no division',
            },
            {
              key: 'offers',
              value: offers !== null ? offers : 'No offer',
            },
            {
              key: 'userType',
              value: 'student',
            },
            {
              key: 'createdAt',
              value: res.data?.payload?.user?.created_at,
            },
          ],
        });
        const eventArrayChange = new Event('arrayChanged');
        window.dispatchEvent(eventArrayChange);
      }
    });
  } catch (e) {
    if (e?.response?.status === 401) {
      const adminID = localStorage.getItem('admin_user_id');
      if (adminID && adminID.includes('?redirectTo=RegisterPage')) {
        window.location = 'https://education-autrement.takiacademy.com/register';
        localStorage.removeItem('admin_user_id');
      } else {
        dispatch(logoutSuccess());
        window.location = '/login';
      }
    }

    //return console.error(e.message);
  }
};

export const editProfile = (data) => async (dispatch) => {
  const new_key = new Date().getTime() + Math.random();
  try {
    dispatch(
      enqueueSnackbar({
        message: 'En traitement...',
        options: {
          key: new_key,
          variant: 'info',
          persist: true,
        },
      })
    );
    const res = await api.post('user/update', data);
    dispatch(me());
    dispatch(closeSnackbar(new_key));
    dispatch(
      enqueueSnackbar({
        message: res.data.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
        },
      })
    );
    if (res.data.credential) {
      window.location = '/confirm';
      localStorage.setItem('credential', res.data.credential);
    }
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        if (e.response.data.errors.birthDate) {
          for (key in e.response.data.errors.birthDate) {
            message = 'Birthdate: '.concat(e.response.data.errors.birthDate[0]);
          }
        } else if (e.response.data.errors.name) {
          for (key in e.response.data.errors.name) {
            message = 'Name: '.concat(e.response.data.errors.name[0]);
          }
        } else if (e.response.data.errors.lastName) {
          for (key in e.response.data.errors.lastName) {
            message = 'Last Name: '.concat(e.response.data.errors.lastName[0]);
          }
        } else {
          for (key in e.response.data.errors) {
            message = e.response.data.errors[key][0];
          }
        }
      } else {
        message = e.response.data.message;
      }
      dispatch(closeSnackbar(new_key));
      dispatch(
        enqueueSnackbar({
          message: formatMessages(message),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
    }
    return console.error(e.message);
  }
};

export const editStatus =
  ({
    country,
    state,
    delegation,
    institute,
    name,
    lastName,
    birthDate,
    phone,
    email,
    division,
    optionalSubject,
    gender,
  }) =>
    async (dispatch) => {
      const new_key = new Date().getTime() + Math.random();
      try {
        dispatch(
          enqueueSnackbar({
            message: 'En traitement...',
            options: {
              key: new_key,
              variant: 'info',
              persist: true,
            },
          })
        );
        const res = await api.put('/user/update', {
          country,
          state,
          delegation,
          institute,
          name,
          lastName,
          birthDate,
          phone,
          email,
          division,
          optionalSubject,
          gender,
        });
        dispatch(me());
        dispatch(closeSnackbar(new_key));
        dispatch(
          enqueueSnackbar({
            message: res.data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
      } catch (e) {
        dispatch(closeSnackbar(new_key));
        dispatch(
          enqueueSnackbar({
            message: e.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
            },
          })
        );
        if (e?.response?.status === 401) {
          const adminID = localStorage.getItem('admin_user_id');
          if (adminID && adminID.includes('?redirectTo=RegisterPage')) {
            window.location = 'https://education-autrement.takiacademy.com/register';
            localStorage.removeItem('admin_user_id');
          } else {
            dispatch(logoutSuccess());
            window.location = '/login';
          }
        }
        return console.error(e.message);
      }
    };

export const editPassword = createAsyncThunk(
  'editPassword',
  async (newReq, thunkAPI) => {
    const new_key = new Date().getTime() + Math.random();
    try {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: 'En traitement...',
          options: {
            key: new_key,
            variant: 'info',
            persist: true,
          },
        })
      );
      const res = await api.put('/user/change-password', newReq);
      thunkAPI.dispatch(closeSnackbar(new_key));
      if (res.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: 'Le Mot de Passe est Modifié',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
        );
        return await res.data;
      }
      throw new Error(res.statusText);
    } catch (e) {
      if (e.response) {
        let message = '';
        let key;
        if (e.response.data.errors) {
          for (key in e.response.data.errors) {
            message = e.response.data.errors[key][0];
          }
        } else {
          message = e.response.data.message;
        }
        thunkAPI.dispatch(closeSnackbar(new_key));
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              anchorOrigin: {
                vertical: 'Bottom',
                horizontal: 'center',
              },
            },
          })
        );
      }

      return console.error(e.message);
    }
  }
);
export const getSubscriptionWarning = () => (dispatch) => {
  return api
    .get(`alerts`)
    .then((response) => {
      dispatch(warningSuccess(response.data));
    })
    .catch((e) => { });
};

export const completeRegister = (data) => async (dispatch) => {
  const new_key = new Date().getTime() + Math.random();
  try {
    const res = await api.post('user/update', data);
    dispatch(me());
    dispatch(closeSnackbar(new_key));
    dispatch(
      enqueueSnackbar({
        message: res.data.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
        },
      })
    );
    if (res.data) {
      window.location = '/confirm';
    }
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      dispatch(closeSnackbar(new_key));
      dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        })
      );
    }
    return console.error(e.message);
  }
};

export const getReasons = () => async (dispatch) => {
  try {
    await api.get(`/edit-division-reasons`).then((response) => dispatch(reasonsSuccess(response)));
  } catch (error) {
    throw error;
  }
};

export const editDivision = (newDivision, reason2, reason) => async (dispatch) => {
  try {
    const res = await api.post(`/edit-division`, {
      newDivision,
      reason2,
      reason,
    });
    dispatch(me());
    dispatch(
      enqueueSnackbar({
        message: res.data.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
        },
      })
    );
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      return console.error(message);
    }
  }
};

export const addParentPhone = (phone2) => async (dispatch) => {
  dispatch(startAddParentPhoneLoading());
  try {
    const res = await api.put(`/user/add-parent-phone`, {
      phone2,
    });
    if (res.status === 200) {
      dispatch(me());
      dispatch(addParentPhoneSuccess());
      dispatch(closeModal('add-parent-phone'));
      dispatch(
        enqueueSnackbar({
          message: i18n.t(res.data.message),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        })
      );
      window.location = '/subjects';
    }
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      dispatch(hasAddParentPhoneError());
      dispatch(
        enqueueSnackbar({
          message: i18n.t(message),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        })
      );
      return console.error(message);
    }
  }
};

// Slice
const slice = createSlice({
  name: 'user',
  initialState: {
    isParentPhoneLoading: 'idle',
    user: null,
    details: null,
    informations: null,
    isLoading: false,
    permissions: {},
    loginSocialLinks: null,
    geoIp: null,
    divisions: null,
    states: null,
    success: null,
    digitalVirgo: false,
    editPasswordData: {
      status: 'idle',
      data: [],
      error: null,
    },
  },
  reducers: {
    startLoading: (state, action) => {
      state.isLoading = true;
    },
    startAddParentPhoneLoading: (state) => {
      state.isParentPhoneLoading = 'loading';
    },
    addParentPhoneSuccess: (state) => {
      state.isParentPhoneLoading = 'succeeded';
    },
    hasAddParentPhoneError: (state, action) => {
      state.isParentPhoneLoading = 'failed';
    },

    meSuccess: (state, action) => {
      const options = [
        { label: 'id', accessor: 'user.id' },
        { label: 'name', accessor: 'user.name' },
        { label: 'first_name', accessor: 'user.name' },
        { label: 'last_name', accessor: 'user.last_name' },
        { label: 'email', accessor: 'user.email' },
        { label: 'phone', accessor: 'user.phone' },
        { label: 'phone2', accessor: 'user.phone2' },
        { label: 'gender', accessor: 'user.gender' },
        { label: 'birth_date', accessor: 'user.birth_date' },
        { label: 'avatar', accessor: 'user.avatar' },
        { label: 'points', accessor: 'user.points' },
        { label: 'division_id', accessor: 'user.division.id' },
        { label: 'division', accessor: 'user.division.name' },
        { label: 'optionalSubject', accessor: 'user.optional_subject.name' },
        { label: 'optionalSubject_id', accessor: 'user.optional_subject.id' },
        { label: 'country', accessor: 'user.county.name' },
        { label: 'state_id', accessor: 'user.state.id' },
        { label: 'state', accessor: 'user.state.name' },
        { label: 'delegation_id', accessor: 'user.delegation.id' },
        { label: 'delegation', accessor: 'user.delegation.name' },
        { label: 'institute_id', accessor: 'user.institute.id' },
        { label: 'institute', accessor: 'user.institute.name' },
        { label: 'offers', accessor: 'user.affiliations' },
        { label: 'completed', accessor: 'user.completed' },
        { label: 'verified', accessor: 'user.verified' },
        { label: 'country_id', accessor: 'user.country.id' },
        { label: 'google_id', accessor: 'user.google_id' },
        { label: 'facebook_id', accessor: 'user.facebook_id' },
        { label: 'hasSession', accessor: 'user.hasSession' },
        { label: 'freeHours', accessor: 'user.freeHours' },
        { label: 'isTest', accessor: 'user.istest' },
        { label: 'hasEditDivision', accessor: 'user.hasEditDivision' },
        { label: 'businessId', accessor: 'user.business.id' },
        { label: 'takeTrialAdvantage', accessor: 'user.takeTrialAdvantage' },
        { label: 'takeTrialAdvantage', accessor: 'user.takeTrialAdvantage' },
        { label: 'gift_code', accessor: 'user.gift_code' },
        { label: 'gift_point', accessor: 'user.gift_point' },
        { label: 'passwordChangedAt', accessor: 'user.password_changed_at' },
        { label: 'hasPhoneParent', accessor: 'hasPhoneParent' },
        { label: 'notificationPreferences', accessor: 'user.notification_preferences' },
        { label: 'notificationToken', accessor: 'user.notification_token' },
      ];
      state.permissions = {
        'forum': action.payload.permissions.has_forum,
        'exam': action.payload.permissions.has_exam,
        'isProfSon': action.payload.permissions.isProfSon,
        'versPilote': action.payload.permissions.versPilote,
        'freeHours': action.payload.permissions.freeHours,
        'discount': action.payload.discountCode,
        'discountType': action.payload.discountType,
        'discountAmount': action.payload.discountAmount,
        'hasEditDivision': action.payload.permissions.hasEditDivision,
        'influencer': action.payload.permissions.influencer,
        'influencer': action.payload.permissions.influencer,
        'eligibleUserForGift': action.payload.permissions.eligibleUserForGift,
        'takeTrialAdvantage': action.payload.permissions.takeTrialAdvantage,
        'changePassword': action.payload.permissions.changePassword,
      };

      state.informations = ord(action.payload, options);
      state.digitalVirgoClient = action.payload.digitalVirgoClient;
      state.hasParent = action.payload.hasParent;
      state.hasParentRequest = action.payload.hasParentRequest;
      state.success = true;
    },
    warningSuccess: (state, action) => {
      state.warning = action.payload.payload;
    },
    logoutSuccess: (state, action) => {
      state.user = null;
      state.informations = null;
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('token_type');
      localStorage.removeItem('expires_at');
    },
    reasonsSuccess: (state, action) => {
      state.reasons = action.payload.data.payload;
    },
    EditDivisionSuccess: (state, action) => {
      state.editSuccess = action.payload.data;
    },
    popupsDetails: (state, action) => {
      state.details = action.payload;
    },
  },
  extraReducers: {
    [editPassword.pending]: (state) => {
      state.editPasswordData.status = 'loading';
    },
    [editPassword.fulfilled]: (state, action) => {
      state.editPasswordData.status = 'succeeded';
      state.editPasswordData.data = action.payload;
    },
    [editPassword.rejected]: (state) => {
      state.editPasswordData.status = 'failed';
    },
  },
});
// Actions
const {
  meSuccess,
  warningSuccess,
  logoutSuccess,
  reasonsSuccess,
  hasAddParentPhoneError,
  startAddParentPhoneLoading,
  addParentPhoneSuccess,
  popupsDetails,
} = slice.actions;
export default slice.reducer;
