import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import notification from '../../assets/img/icons/notification.svg';
import settings from '../../assets/img/icons/settings.svg';
import arrowLeft from '../../assets/img/icons/arrow-left.svg';
import Badge from '@material-ui/core/Badge';
import { Typography } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import Tab from '@mui/material/Tab';
import {
  getAllNotifications,
  getAllUnreadNotifications,
  addNewNotification,
} from '../../redux/slices/notifications';
import NotificationPreferenceList from '../NotificationPreferenceList';
import NotificationsList from '../NotificationsList';
import i18n from '../../services/i18n';

const useStyles = makeStyles((theme) => ({
  dropdown: {
    marginLeft: '8px',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));

const Index = () => {
  const { t } = useTranslation();
  const {
    notifications,
    hasMore,
    isNotificationsLoading,
    unreadNotificationsCount,
    unreadNotifications,
    isUnreadNotificationsLoading,
    hasMoreUnread,
    lastPage,
    lastUnreadPage,
  } = useSelector((state) => state.notifications);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [view, setView] = useState('notifications');
  const [value, setValue] = useState('1');
  const anchorRef = React.useRef(null);

  const [page, setPage] = useState(1);
  const [ureadPage, setUnreadPage] = useState(1);

  const [isUnreadNotificationsTab, setUnreadTab] = useState(false);
  const isArabic = i18n.language === 'ar';
  useEffect(() => {
    if (isUnreadNotificationsTab) {
      dispatch(getAllUnreadNotifications());
    }
  }, [isUnreadNotificationsTab]);

  useEffect(() => {
    const channel = new BroadcastChannel('serviceWorker');
    channel.onmessage = (event) => {
      const newNotification = event.data;
      dispatch(addNewNotification(newNotification.notification));
    };

    // return () => {
    //   channel.close();
    // };
  }, []);
  const handleOnClickUnreadNotifications = () => {
    setUnreadTab(true);
  };
  const loadMore = () => {
    if (hasMore && !isNotificationsLoading && page <= lastPage) {
      dispatch(getAllNotifications(page + 1));
      setPage((prevPage) => prevPage + 1);
    }
  };

  const loadMoreUnreadNotifications = () => {
    if (hasMoreUnread && !isUnreadNotificationsLoading && ureadPage <= lastUnreadPage) {
      dispatch(getAllUnreadNotifications(ureadPage + 1));
      setUnreadPage((prevPage) => prevPage + 1);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setPage(1);
    setUnreadPage(1);
    dispatch(getAllNotifications(page));
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSettingsClick = () => {
    setView('settings');
  };

  const handleBackClick = () => {
    setView('notifications');
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div className={`${classes.dropdown} notification ${isArabic ? 'rtl' : ''}`}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge
          color="secondary"
          badgeContent={!isNaN(unreadNotificationsCount) ? unreadNotificationsCount : 0}
          className="nb-notifications"
          showZero={false}

        >
          <img alt="message" src={notification} className="notification-icon" />
        </Badge>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={isArabic ? 'bottom-start' : 'bottom-end'}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              marginTop: '1.5rem',
              marginRight: isArabic ? '0' : 'auto',
              marginLeft: isArabic ? 'auto' : '0',
              width: '70vw',
              maxWidth: '450px',
              minWidth: '280px',
              zIndex: 1200,
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <div className="notification-popper">
                    <div className="title-container">
                      {view === 'settings' && (
                        <img alt="message" src={arrowLeft} onClick={handleBackClick} />
                      )}
                      <Typography className="menu-item-title">{t('Notifications')}</Typography>
                    </div>
                    {view === 'notifications' && (
                      <img alt="message" src={settings} onClick={handleSettingsClick} />
                    )}
                  </div>
                  {view === 'notifications' && (
                    <TabContext value={value}>
                      <TabList
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        variant="fullWidth"
                        textColor="primary"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          paddingBottom: '0.8rem',
                          width: '95%',
                          direction: isArabic ? 'rtl' : 'ltr',
                        }}
                        TabIndicatorProps={{
                          style: {
                            bottom: 0,
                            width: '50%',
                            marginLeft: '10px',
                            marginRight: '10px',
                            color: '#2ba7df',
                          },
                        }}
                      >
                        <Tab label={t('all notifications')} value="1" />
                        <Tab
                          label={t('unread')}
                          value="2"
                          onClick={handleOnClickUnreadNotifications}
                        />
                        {/* <Tab label={t('Mention')} value="3" disabled /> */}
                      </TabList>
                      <TabPanel value="1" style={{ padding: 0 }}>
                        <NotificationsList
                          notifications={notifications}
                          handleClose={handleClose}
                          loadMore={loadMore}
                          hasMore={hasMore && page <= lastPage}
                          isLoading={isNotificationsLoading}
                        />
                      </TabPanel>
                      <TabPanel value="2" style={{ padding: 0 }}>
                        <NotificationsList
                          notifications={unreadNotifications}
                          handleClose={handleClose}
                          loadMore={loadMoreUnreadNotifications}
                          hasMore={hasMoreUnread && ureadPage <= lastUnreadPage}
                          isLoading={isUnreadNotificationsLoading}
                        />
                      </TabPanel>
                    </TabContext>
                  )}
                  {view === 'settings' && <NotificationPreferenceList />}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default Index;
